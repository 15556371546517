import React from 'react'
import styled from 'styled-components'
// import Forcefield from 'react-forcefield'
import PageTitle from '../components/PageTitle'
// import Loading from '../images/loading_icon.gif'
// import ChimeInImage from '../images/CI_Logo_201.png'
// import SoiHighlight from '../components/SoiHighlight'
import '../components/Layout/layout.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Link from '../components/Link'

const Home = () => (
  <Layout upper={<Hero />}>
    <div className="row">
      <div className="col-sm-4 col-lg-3 my-4">
        <p className="">
          <div className="list-group list-group-flush no-underline">
            <button type="button" className="list-group-item list-group-item-action active disabled small" aria-current="true">
              Institutional Research
            </button>
            <Link to="/reports/?Report%20Type[0][0]=Common%20Data%20Set"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Common Data Set</span></button></Link>
            {/* <Link to="/other-resources/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> External Resources</span></button></Link> */}
            <a href="https://oira.ua.edu/test/reports/?Report%20Type[0][0]=Enrollment%20at%20a%20Glance"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Enrollment at a Glance</span></button></a>
            <Link to="/interactiveFB/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Factbook</span></button></Link>
            <Link to="/requests/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Information Requests</span></button></Link>
            <Link to="/internalDepartments/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Internal Reports<br />(Login Required)</span></button></Link>
            <a href="http://oira.ua.edu/HEA/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> OIRA HEAct Information</span></button></a>
            <Link to="/staff/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> OIRA Staff</span></button></Link>
            <Link to="/publicReports/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Public Reports</span></button></Link>
            <a href="https://oiratemp.ua.edu/stat-profiles/view/majors"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Statistical Profiles<br />(Login Required)</span></button></a>
            <Link to="/soi/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Student Opinions of Instruction</span></button></Link>
            <a href="http://syllabi.ua.edu"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> Syllabus Archive<br />(Pre-Summer 2023)</span></button></a>
            {/* <a href="https://oira.ua.edu/strategic-measures-2022/"><button type="button" className="list-group-item list-group-item-action small smooth px-1"><span className="shift smooth"><i class="bi bi-chevron-right"></i> UA Strategic Measures</span></button></a> */}
          </div>
        </p>
      </div>
      <div className="col-sm-8 col-lg-9">
        <PageTitle name="Welcome" site="OIRA" />
        <h2>Mission</h2>
        <h3 id="goal-to-be-a-trusted-provider-of-timely-relevant-and-unbiased-information-and-services-">
          Goal: To be a trusted provider of timely, relevant, and unbiased
          information and services.
        </h3>
        <p>
        The mission of the Office of Institutional Research and Assessment is to provide institutional information to external reporting groups as well as University decision-makers in support of planning, policy formulation, resource allocation, and the advancement of University operations. This is accomplished by managing and stewarding official University data, conducting data analysis, performing assessment research, and creating both static and dynamic visualization reports.
        </p>

        {/* <h3>What we offer</h3>
        <p>
          Below you can find a list of helpful resources OIRA offers to the UA community.
        </p>
        <div>
          <p>
            <Link to="/interactiveFB/">The University of Alabama Factbook</Link>
          </p>

          <p>
            <Link to="/reports/">OIRA Public Reports</Link>
          </p>

          <p>
            <Link to="/internalReports/">Internal Reports</Link>
          </p>

          <p>
            <Link to="/requests/">OIRA Information Requests</Link>
          </p>

          <p>
            <a href="https://oira.ua.edu/new/soi/">Student Opinions of Instruction (SOI)</a>
          </p>
        </div> */}

        <h3 id="core-functions">Core Functions</h3>
        <p>
        Mandated regulatory reporting to external groups [National Center for Education Statistics (NCES), Alabama Commission on Higher Education (ACHE), Southern Association for Colleges and Schools Commission on Colleges (SACSCOC), and University of Alabama System (UAS), as well as media and ranking agencies that have bearing on the University image and reputation]. Decision-support information for University units' (leadership administration, colleges or schools, departments, programs, or committees) administrative and operational support. Responsible for creating, managing, and stewardship of official University data and information. Assessment-related research to evaluate and illuminate issues associated with the University units' operations and functions. Create and distribute static and dynamic information visualization reports to advance the University units' operations and enhance the information flow within the University.
        </p>
        <p>
          This office has access to a great deal of confidential data regarding the
          campus, personnel, and students. It is critical for the office to maintain
          the trust of the campus in its handling of these data. Therefore, this
          office adheres to the accepted “<a href="https://www.airweb.org/ir-data-professional-overview/statement-of-ethical-principles/principles">Code of Ethics</a>” for our profession.
        </p>
        <h3 id="definitions-of-institutional-research">
          Definitions of Institutional Research
        </h3>
        <blockquote>
          <p>
            “Institutional research has to do with what decisions makers need to
            know about an institution, its educational objectives, goals and
            purposes, environmental factors, process, and structures to more wisely
            use its resources, more successfully attain its objectives and goals,
            and to demonstrate integrity and accountability in so doing.”
          </p>
        </blockquote>
        <p>
          Dressel, P. L. The shaping of institutional research and planning.
          Research in Higher Education, 51 (2).
        </p>
        <blockquote>
          {/* <p>
            “Institutional research is research conducted within an institution of
            higher education in order to provide information which supports
            institutional planning, policy formulation and decision making.”
          </p> */}
          <p>
            "Institutional research is the sum total of all activities directed at empirically describing the full spectrum of functions (educational, administrative, and support) at a college or university. Institutional research activities examine those functions in their broadest definitions and, in the context of both internal and external environments, embrace data collection and analytical strategies in support of decision making at the institution."
          </p>
        </blockquote>
        {/* <p>
          Saupe, Joe L. The Functions of Institutional Research. Tallahassee, FL:
          Association for Institutional Research, 1981.
        </p> */}
        <p>
          Middaugh, M.F. (1990). "The Nature and Scope of Institutional Research," Organizing Effective Institutional Research Offices. New Directions for Institutional Research, no. 66. San Francisco, Jossey-Bass.
        </p>
      </div>
    </div>
    
    {/*
      <iframe width="800" height="600" src="https://app.powerbi.com/view?r=eyJrIjoiNmYyMzMyNzgtYTNmYi00YzQ0LThlZWUtMmJhMTZiODM3ODE5IiwidCI6IjJhMDA3MjhlLWYwZDAtNDBiNC1hNGU4LWNlNDMzZjNmYmNhNyIsImMiOjN9" frameborder="0" allowFullScreen="true"></iframe>
    <div>
      <h2>Your Voice is Important — Please CHIME IN</h2>
      <a href="https://oira.ua.edu/chime-in/">
        <img src={ChimeInImage} alt="Chime In Logo" />
      </a>
      <p>
        The University of Alabama is committed to fulfilling the goals of our
        strategic plan,{' '}
        <em>
          <a href="https://www.ua.edu/strategicplan/">Advancing the Flagship</a>
        </em>
        . In order to further address a key goal of the plan to{' '}
        <strong>
          &quot;Provide opportunities and resources that facilitate work-life
          balance and enhance the recruitment and retention of outstanding
          faculty and staff,&quot;
        </strong>{' '}
        the University is partnering with ModernThink to survey all faculty and
        staff regarding their workplace experience.
      </p>
      <p>
        The survey will give guidance to leadership for improving UA workplaces.
        President Stuart Bell and the entire campus leadership team support the
        successful implementation of the survey and are committed to using the
        results to make improvements where possible.
      </p>
      <p>
        →{' '}
        <a href="https://oira.ua.edu/chime-in/">
          Learn more on the Chime In page
        </a>
      </p>
    </div>
    <hr />
    
    <Forcefield>
      <DisplayOver810>
        <h2>Undergraduate Students From Alabama By County</h2>
        <div style={{ background: `url(${Loading}) center center no-repeat` }}>
          <iframe
            src="https://oira.ua.edu/maps/ache/"
            style={{ border: '1px #eee solid' }}
            title="Ache map"
            name="myiFrame"
            scrolling="no"
            height="800px"
            width="100%"
            allowFullScreen
          />
        </div>
      </DisplayOver810>
    </Forcefield>
    */}
  </Layout>
)

export default Home

const DisplayOver810 = styled.div`
  @media (max-width: 810px) {
    display: none;
  }
`